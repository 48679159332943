 
import React, { useState, useEffect } from "react";
import axios from "axios"; // Import axios
import { Link } from "react-router-dom";
import image1 from "./Images/image1.JPG";
import image2 from "./Images/image2.JPG";
import image3 from "./Images/image3.JPG";
import image4 from "./Images/image4.JPG";

import "./CSS/LogoStyle.css";

const Home = () => {
  const [selectedImage, setSelectedImage] = useState(0);
  const allImages = [image1, image2, image3, image4];

  // New state for visit count
  const [visitCount, setVisitCount] = useState(0);

  useEffect(() => {
    // Fetch visit count from the API
    const fetchVisitCount = async () => {
      try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/count`);
          setVisitCount(response.data.count);
          
          await axios.patch(`${process.env.REACT_APP_API_URL}/count/visitadd`);
      } catch (error) {
          console.error("Error fetching visit count:", error.response ? error.response.data : error.message);
          setVisitCount("Error fetching visit count");
      }
  };
  

    fetchVisitCount();
    // Existing interval logic...
    const timer = setInterval(() => {
      setSelectedImage((prev) => (prev + 1) % allImages.length);
    }, 3000);

    return () => clearInterval(timer);
  }, [allImages.length]);

  return (
    <div>
    
  <p>Site Visit Counter: <b className="text-danger">{visitCount}</b></p>


      <div
        className="mt-2"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <h2
          style={{
            color: "#331e38",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.4)",
            fontFamily: "ArialBlack",
            fontSize: "32px",
            letterSpacing: "1px",
            padding: "10px",
            borderRadius: "10px",
            background: "rgba(255, 255, 255, 0.8)",
            display: "inline-block",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          }}
        >
          WELCOME TO TECHINFOLAB
        </h2>
      </div>

      <br />

      {/* Image Slider */}
      <div className="row">
        <div
          className="text-center mt-3 px-4 relative"
          style={{
            boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.6)",
            position: "relative",
          }}
        >
          <img
            src={allImages[selectedImage]}
            className="d-block w-100 img-shadow"
            alt="Slide"
          />

          <button
            className="btn btn-secondary mt-3"
            style={{
              position: "absolute",
              top: "50%",
              left: "10px",
              color: "white",
              transform: "translateY(-50%)",
              fontSize: "30px",
            }}
            onClick={() =>
              setSelectedImage(
                (selectedImage - 1 + allImages.length) % allImages.length
              )
            }
          >
            &lt;
          </button>
          <button
            className="btn btn-secondary mt-3"
            style={{
              position: "absolute",
              top: "50%",
              right: "10px",
              color: "white",
              transform: "translateY(-50%)",
              fontSize: "30px",
            }}
            onClick={() =>
              setSelectedImage((selectedImage + 1) % allImages.length)
            }
          >
            &gt;
          </button>
        </div>
        <br />
      </div>
      <br />
      <hr />
      <br />
      {/* Content Area */}
      <div className="container-fluid mt-4 row">
        <div className="container">
          <h2 className="text-center">Single Page Application</h2>
          <p className="text-justify">
            Techinfolab offers Single Page Application (SPA) built in React
            Framework with Node.js server. It is an amazing application which is
            not only fast but reliable with following features: -
          </p>
          <h4>Features</h4>
          <p className="text-justify">Student Management, Fee Management, Attendance, Assignment, Staff & Salary Management, Result, Date Sheet & <strong>Paper Management</strong>, Expense Management, Statistical Dashboard and Monthly Report.</p>
          <p>
            You can operate the application here.{" "}
            <Link to="/dashboard">Single Page Application</Link>
          </p>
        </div>
        <br />
</div>
<div className="row">
        <div className="col-md-6">
          <h2 className="py-3 text-center">Single Page Application Demo</h2>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/J2o-6FYSsjM?si=AXX-pZMMA_WLAtJS" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
        </div>
        <div className="col-md-6">
          <h2 className="py-3 text-center">Question Paper Preparation</h2>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/K4Z4PfVmqok?si=9mLhxdLxbGLPj3Sp" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
        </div>
      
        </div>
        <div className="row">
        <br />
        <hr />
        <br />
        <div className="col-md-6">
          <h2 className="py-3 text-center">Multi Page Application</h2>
          <p className="text-justify">
            Techinfolab also offers Multi-page Application built in Codeigniter
            with the help of Ajax and JQuery. It is also a reliable application: -
          </p>
          <h4>Features</h4>
          <p className="text-justify">Student Management, Fee Management, Attendance, Assignments, Staff & Salary Management, Result Management, Expense Management and Statistical Dashboard</p>

          <p>
            You can operate the application here.{" "}
            <Link to="https://techinfolab360.xyz/codeigniter">
              Multi Page Application
            </Link>
          </p>
        </div>
        <div className="col-md-6">
          <h2 className="py-3 text-center">Multipage Application.</h2>
         
          <iframe
  width="560"
  height="315"
  src="https://www.youtube.com/embed/ipQT-B_NcY0?si=ebqLepy67fwplJUV"
  title="YouTube video player"

  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  referrerPolicy="strict-origin-when-cross-origin"
  allowFullScreen
></iframe>

        </div>
      </div>

      <hr />
     

       <hr />
      <br />
  

      {/* Footer */}
      <div className="footer">
        <footer
          className="text-center mt-4 py-1"
          style={{ backgroundColor: "grey", color: "white" }}
        >
          <p style={{ color: "white" }}>
            Techinfolab, Website:{" "}
            <a href="https://techinfolab360.xyz" style={{ color: "white" }}>
              https://techinfolab360.xyz
            </a>
            , E-mail:{" "}
            <a
              href="mailto:techinfolab360@gmail.com"
              style={{ color: "white" }}
            >
              techinfolab360@gmail.com
            </a>{" "}
           
          </p>
        </footer>
      </div>
    </div>
  );
};

export default Home;
