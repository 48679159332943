import React, { useEffect, useState, useContext } from 'react';
import { useTable } from 'react-table';
import axios from 'axios';
import userContext from "../context/UserContext";
import { useNavigate } from "react-router-dom";
import { PDFDownloadLink, Document, Page, StyleSheet, View, Text } from '@react-pdf/renderer';

// Styles for the PDF document
const pdfStyles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: 14,
    marginBottom: 5,
    textAlign: "center",
    fontWeight: "bold",
  },
  table: {
    display: "table",
    width: "100%",
    marginTop: 5,
    border: '1px solid #000',
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
  },
  tableCell: {
    width: "14.28%",
    border: '1px solid #000',
    padding: 5,
    textAlign: "center",
    fontSize: 10,
  },
  headerCell: {
    backgroundColor: "#f0f0f0",
    fontWeight: "bold",
    fontSize: 10,
  },
});

// PDF Component to render the date sheet
const DateSheetPdfDocument = ({ records, examName, academicYear }) => {
  if (!records.length) return <Text>No Data Available</Text>;

  const dateSet = Array.from(new Set(records.map(record => new Date(record.date).toLocaleDateString('en-CA'))));
  const standardsSet = Array.from(new Set(records.map(record => record.standard)));

  const dateMatrix = dateSet.map(date => {
    const row = { date };
    standardsSet.forEach(standard => {
      const matchedRecord = records.find(record => new Date(record.date).toLocaleDateString('en-CA') === date && record.standard === standard);
      row[standard] = matchedRecord ? matchedRecord.subject : '';
    });
    return row;
  });

  return (
    <Document>
      <Page size={[842, 595]} style={pdfStyles.page}>
        <View style={pdfStyles.table}>
          <Text style={pdfStyles.title}>Date Sheet - {examName}, {academicYear}</Text>
          <View style={pdfStyles.tableRow}>
            <View style={{ ...pdfStyles.tableCell, ...pdfStyles.headerCell }}>
              <Text>Date</Text>
            </View>
            {standardsSet.map(standard => (
              <View key={standard} style={{ ...pdfStyles.tableCell, ...pdfStyles.headerCell }}>
                <Text>{standard}</Text>
              </View>
            ))}
          </View>
          {dateMatrix.map((row, index) => (
            <View key={index} style={pdfStyles.tableRow}>
              <View style={pdfStyles.tableCell}>
                <Text>{row.date}</Text>
              </View>
              {standardsSet.map(standard => (
                <View key={standard} style={pdfStyles.tableCell}>
                  <Text>{row[standard]}</Text>
                </View>
              ))}
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

const DateSheetTable = () => {
  const [records, setRecords] = useState([]);
  const [standardsList, setStandardsList] = useState([]);
  const [examList, setExamList] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [selectedExamName, setSelectedExamName] = useState('');
  const [selectedAcademicYear, setSelectedAcademicYear] = useState('');
  const { token } = useContext(userContext);
  const navigate = useNavigate();

  // Fetch exams and years from the API
  useEffect(() => {
    const fetchExamsAndYears = async () => {
      try {
        const { data: exams } = await axios.get(`${process.env.REACT_APP_API_URL}/datesheet/examination`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const { data: years } = await axios.get(`${process.env.REACT_APP_API_URL}/datesheet/years`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setExamList(exams);
        setYearList(years);
      } catch (error) {
        console.error('Error while fetching exams and years:', error);
      }
    };
    fetchExamsAndYears();
  }, [token]);

  // Fetch date sheet records based on selected exam and year
  useEffect(() => {
    const fetchRecords = async () => {
      if (selectedExamName && selectedAcademicYear) {
        try {
          const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/datesheet/report`, {
            params: { year: selectedAcademicYear, exam: selectedExamName },
            headers: { Authorization: `Bearer ${token}` },
          });
          const formattedData = data.records.map(record => ({
            date: record.date,
            subject: record.subject,
            standard: record.standard,
          }));
          setRecords(formattedData);
          setStandardsList(Array.from(new Set(formattedData.map(item => item.standard))));
        } catch (error) {
          console.error('Error fetching records:', error);
        }
      }
    };
    fetchRecords();
  }, [selectedExamName, selectedAcademicYear, token]);

  // Table columns definition
  const columns = React.useMemo(() => {
    const baseColumns = [{
      Header: 'Date',
      accessor: 'date',
      Cell: ({ value }) => new Date(value).toLocaleDateString('en-CA'),
    }];

    // Dynamically add columns for each standard
    standardsList.forEach(standard => {
      baseColumns.push({
        Header: standard,
        accessor: record => (record.standard === standard ? record.subject : ''),
      });
    });

    return baseColumns;
  }, [standardsList]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data: records });

  return (
    <div>
      <div className="row">
        <div className='col-md-4'>
          <select
            value={selectedExamName}
            onChange={e => setSelectedExamName(e.target.value)}
            className="form-control mx-2 shadow p-2 rounded-pill border-3 border-info"
          >
            <option value="">--Choose Exam--</option>
            {examList.map(exam => <option key={exam.exam} value={exam.exam}>{exam.exam}</option>)}
          </select>
        </div>
        <div className='col-md-4'>
          <select
            value={selectedAcademicYear}
            onChange={e => setSelectedAcademicYear(e.target.value)}
            className="form-control mx-2 shadow p-2 rounded-pill border-3 border-info"
          >
            <option value="">--Choose Year--</option>
            {yearList.map(year => <option key={year.year} value={year.year}>{year.year}</option>)}
          </select>
        </div>
        <div className='col-md-4'>
          <button className='btn btn-info' onClick={() => navigate('/dashboard/datesheet')}>
            Back
          </button>
        </div>
      </div>
      <h3 className='text-center'>
        {selectedExamName && selectedAcademicYear ? `DATE SHEET - ${selectedExamName}, ${selectedAcademicYear}` : 'Select Exam and Year'}
      </h3>
      <table {...getTableProps()} style={{ border: '1px solid black', width: '100%' }}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()} style={{ border: '1px solid black', padding: '10px' }}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {records.length === 0 ? (
            <tr>
              <td colSpan={columns.length} style={{ textAlign: 'center' }}>No Results Found</td>
            </tr>
          ) : (
            rows.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()} style={{ border: '1px solid black', padding: '10px' }}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })
          )}
        </tbody>
      </table>

      {/* PDF Download Link */}
      <div className="text-center mt-4">
        <PDFDownloadLink
          document={<DateSheetPdfDocument records={records} examName={selectedExamName} academicYear={selectedAcademicYear} />}
          fileName={`Date_Sheet_${selectedExamName}_${selectedAcademicYear}.pdf`}
        >
          {({ loading }) => (loading ? 'Loading document...' : 'Download Date Sheet PDF')}
        </PDFDownloadLink>
      </div>
    </div>
  );
};

export default DateSheetTable;
