import { Document, Page, StyleSheet, View, Text, Image } from "@react-pdf/renderer";
import React from "react";
import logo from '../Images/logo.JPG';

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    flexDirection: "row",
    wrap: false,
  },
  logo: {
    width: 30,
    height: 30,
  },
  horizontalLine: {
    borderBottom: 1,
    borderBottomColor: '#1c0dce', // black color
    borderBottomStyle: 'solid',
    width: '100%',
    height: 0.2,
  },
  view: {
    flex: 1,
    margin: 10,
    padding: 10,
    border: "1px solid #000",
    borderRadius: 5,
  },
  title: {
    fontSize: 14,
    marginBottom: 10,
    textAlign: "center",
    fontWeight: "bold",
  },
  header: {
    fontSize: 12,
    marginBottom: 5,
    textAlign: "center",
    fontWeight: "bold",
  },
  text: {
    fontSize: 9,
  },
  textmicro: {
    fontSize: 9,
  },
  boldText: {
    fontSize: 10,
    fontWeight: "bold",
  },
  table: {
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    padding: 5,
    marginBottom: 0,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    borderStyle: "solid",
    borderWidth: 0,
    borderColor: "#000",
    padding: 5,
    flex: 1,
  },
  tableCellColor: {
    borderStyle: "solid",
    borderWidth: 0,
    borderColor: "#000",
    backgroundColor:"#6a260e",
    color:"#ffffff",
    padding: 5,
    fontSize:10,
    fontWeight:"bold",
    flex: 1,
  },
  footer: {
    position: "absolute",
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: "left",
    fontSize: 9,
  },
  tableCellBold: { 
    borderStyle: "solid", 
    borderWidth: 0, 
    borderColor: "#000", 
    padding: 5, 
    flex: 1, 

    fontWeight: "bold" 
  },
});

const LineBreak = () => {
  return <Text style={{ height: 10  }}>&nbsp;</Text>;
};
const Table = ({ children }) => {
  return <View style={styles.table}>{children}</View>;
};

const HorizontalLine = () => (
  <View style={styles.horizontalLine} />
);


const TableRow = ({ children }) => {
  return <View style={styles.tableRow}>{children}</View>;
};

const TableCell = ({ children, bold }) => {
  return (
    <View style={styles.tableCell}>
      <Text style={bold ? styles.boldText : styles.text}>{children}</Text>
    </View>
  );
};
const TableCellColor = ({ children, bold }) => {
  return (
    <View style={styles.tableCellColor}>
      <Text style={bold ? styles.boldText : styles.text}>{children}</Text>
    </View>
  );
};

// Define the mapping for month numbers to month names
const monthNames = [
  "Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

const getMonthName = (monthNumber) => {
  // Ensure the month number is between 1 and 12
  if (monthNumber < 1 || monthNumber > 12) return "";
  return monthNames[monthNumber - 1]; // Adjust for zero index
};

const BulkPdf = ({ invoices }) => {
    return (
      <Document>
        {invoices.map((data) => (
          <Page key={data.idf} size="A4" orientation="landscape" style={styles.page}>
             {/* School Copy */}
        <View style={styles.view}>
        <TableRow>
        <View style={{ flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
  <Image src={logo} alt="Logo" style={styles.logo} />
  <View style={{ flexDirection: 'column', alignItems: 'center' }}>
    <Text style={{ fontWeight: "Bold", fontSize: 14 }}> Your School Name here </Text>
    <Text style={{ fontWeight: "Bold", fontSize: 14 }}> address goes here </Text>
  </View>
  <View style={{ width: 10 }} /> 
</View>
</TableRow>
<Text style={[styles.text, { textAlign: "center" }]}>Contact: your no</Text>

          <Text style={styles.header}>Institute Copy</Text>
          <br />
          <Table>
            <TableRow>
              <TableCell>Invoice No</TableCell>
              <TableCell>:  {data.idf}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Admission No</TableCell>
              <TableCell>:  {data.fee_adm_no}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Student Name:</TableCell>
              <TableCell>:  {data.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Father Name:</TableCell>
              <TableCell>:  {data.father}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Standard:</TableCell>
              <TableCell>:  {data.FeeStandard}</TableCell>
            </TableRow>
            <TableRow>
              
              <TableCell>Due Date:</TableCell>
                         <TableCell>:  10-{getMonthName(data.fmonth)}-{data.fyear}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Fee Month:</TableCell>
                         <TableCell>:  {getMonthName(data.fmonth)} - {data.fyear}</TableCell>
            </TableRow>
           
          </Table>
          <hr />
          <LineBreak />
          <HorizontalLine />
    
          <Text style={styles.header}>Fee Detail</Text>
          <Table>
            <TableRow>
              <TableCellColor>Description</TableCellColor>
              <TableCellColor>Amount</TableCellColor>
            </TableRow>
            <TableRow>
              <TableCell>Monthly Fee:</TableCell>
              <TableCell>Rs. {data.monthly_fee_feetbl}/-</TableCell>
            </TableRow>

            <TableRow>
              {data.misc_fee > 0 ? (
                <>
                  <TableCell>{data.remarks}:</TableCell>
                  <TableCell>Rs. {data.misc_fee}/-</TableCell>
                </>
              ) : null}
            </TableRow>

            <TableRow>
              {data.exam_fee > 0 ? (
                <>
                  <TableCell>Adm Fee:</TableCell>
                  <TableCell>Rs. {data.adm_fee}/-</TableCell>
                </>
              ) : null}
            </TableRow>
            <TableRow>
              {data.exam_fee > 0 ? (
                <>
                  <TableCell>Exam Fee:</TableCell>
                  <TableCell>Rs. {data.exam_fee}/-</TableCell>
                </>
              ) : null}
            </TableRow>
            <TableRow>
              {data.fine_fee > 0 ? (
                <>
                  <TableCell>Fine Fee:</TableCell>
                  <TableCell>Rs. {data.fine_fee}/-</TableCell>
                </>
              ) : null}
            </TableRow>
            <TableRow>
              {data.arrears > 0 ? (
                <>
                  <TableCell>Arrears:</TableCell>
                  <TableCell>Rs. {data.arrears}/-</TableCell>
                </>
              ) : null}
            </TableRow>
            <TableRow>
  {data.total_fee > 0 ? (
    <>
      <TableCellColor>Payable before due date</TableCellColor>
      <TableCellColor>Rs. {data.total_fee}/-</TableCellColor>
    </>
  ) : null}
</TableRow>
<TableRow>
 
    <>
      <TableCell>Late Fee Fine</TableCell>
      <TableCell>Rs. 100/-</TableCell>
    </>

</TableRow>
<TableRow>
<>
      <TableCellColor>Payable after due date</TableCellColor>
      <TableCellColor>Rs. {parseInt(data.total_fee) + 100}/-</TableCellColor>
    </>
 
</TableRow>
          </Table>
       
          <LineBreak />
          <LineBreak />
          <LineBreak />
          <LineBreak />
          <LineBreak />
         
    {/* Footer */}
    <View style={styles.footer}>
       
            <Text style={{ fontSize: 10 }}>
              1. Fee Dues must be paid before 10th of every month.
            </Text>
            <Text style={{ fontSize: 10 }}>
              2. Late fee fine (100) will be charged after due date.
            </Text>
            <Text style={{ fontSize: 10 }}>
              3. Defaulters of more than two months will be strucked off from the school.
            </Text>
           
        <HorizontalLine/>
          <Text style={{ fontSize: 10, textAlign: "center" }}>
            your bank detail
          </Text>
          <Text style={{ fontSize: 10, textAlign: "center" }}>
            A/C No: account no???
          </Text>
        </View>
        </View>
        
        
        {/* Banks Invoice */}
        <View style={styles.view}>
        <TableRow>
        <View style={{ flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
  <Image src={logo} alt="Logo" style={styles.logo} />
  <View style={{ flexDirection: 'column', alignItems: 'center' }}>
    <Text style={{ fontWeight: "Bold", fontSize: 14 }}> Your School Name here </Text>
    <Text style={{ fontWeight: "Bold", fontSize: 14 }}> address goes here </Text>
  </View>
  <View style={{ width: 10 }} /> 
</View>
</TableRow>
<Text style={[styles.text, { textAlign: "center" }]}>Contact: your no</Text>

          <Text style={styles.header}>Bank's Copy</Text>
          <br />
          <Table>
            <TableRow>
              <TableCell>Invoice No</TableCell>
              <TableCell>:  {data.idf}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Admission No</TableCell>
              <TableCell>:  {data.fee_adm_no}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Student Name:</TableCell>
              <TableCell>:  {data.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Father Name:</TableCell>
              <TableCell>:  {data.father}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Standard:</TableCell>
              <TableCell>:  {data.FeeStandard}</TableCell>
            </TableRow>
            <TableRow>
              
              <TableCell>Due Date:</TableCell>
                         <TableCell>:  10-{getMonthName(data.fmonth)}-{data.fyear}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Fee Month:</TableCell>
                         <TableCell>:  {getMonthName(data.fmonth)} - {data.fyear}</TableCell>
            </TableRow>
          </Table>
          <hr />
          <LineBreak />
          <HorizontalLine />
    
          <Text style={styles.header}>Fee Detail</Text>
          <Table>
            <TableRow>
              <TableCellColor>Description</TableCellColor>
              <TableCellColor>Amount</TableCellColor>
            </TableRow>
            <TableRow>
              <TableCell>Monthly Fee:</TableCell>
              <TableCell>Rs. {data.monthly_fee_feetbl}/-</TableCell>
            </TableRow>

            <TableRow>
              {data.misc_fee > 0 ? (
                <>
                  <TableCell>{data.remarks}:</TableCell>
                  <TableCell>Rs. {data.misc_fee}/-</TableCell>
                </>
              ) : null}
            </TableRow>

            <TableRow>
              {data.exam_fee > 0 ? (
                <>
                  <TableCell>Adm Fee:</TableCell>
                  <TableCell>Rs. {data.adm_fee}/-</TableCell>
                </>
              ) : null}
            </TableRow>
            <TableRow>
              {data.exam_fee > 0 ? (
                <>
                  <TableCell>Exam Fee:</TableCell>
                  <TableCell>Rs. {data.exam_fee}/-</TableCell>
                </>
              ) : null}
            </TableRow>
            <TableRow>
              {data.fine_fee > 0 ? (
                <>
                  <TableCell>Fine Fee:</TableCell>
                  <TableCell>Rs. {data.fine_fee}/-</TableCell>
                </>
              ) : null}
            </TableRow>
            <TableRow>
              {data.arrears > 0 ? (
                <>
                  <TableCell>Arrears:</TableCell>
                  <TableCell>Rs. {data.arrears}/-</TableCell>
                </>
              ) : null}
            </TableRow>
            <TableRow>
  {data.total_fee > 0 ? (
    <>
      <TableCellColor>Payable before due date</TableCellColor>
      <TableCellColor>Rs. {data.total_fee}/-</TableCellColor>
    </>
  ) : null}
</TableRow>
<TableRow>
 
    <>
      <TableCell>Late Fee Fine</TableCell>
      <TableCell>Rs. 100/-</TableCell>
    </>

</TableRow>
<TableRow>
<>
      <TableCellColor>Payable after due date</TableCellColor>
      <TableCellColor>Rs. {parseInt(data.total_fee) + 100}/-</TableCellColor>
    </>
 
</TableRow>
          </Table>
       
          <LineBreak />
          <LineBreak />
          <LineBreak />
          <LineBreak />
          <LineBreak />
         
    {/* Footer */}
    <View style={styles.footer}>
       
            <Text style={{ fontSize: 10 }}>
              1. Fee Dues must be paid before 10th of every month.
            </Text>
            <Text style={{ fontSize: 10 }}>
              2. Late fee fine (100) will be charged after due date.
            </Text>
            <Text style={{ fontSize: 10 }}>
              3. Defaulters of more than two months will be strucked off from the school.
            </Text>
           
        <HorizontalLine/>
          <Text style={{ fontSize: 10, textAlign: "center" }}>
            your bank detail
          </Text>
          <Text style={{ fontSize: 10, textAlign: "center" }}>
            A/C No: account no???
          </Text>
        </View>
        </View>
        
        {/* student invoice */}
        <View style={styles.view}>
        <TableRow>
        <View style={{ flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
  <Image src={logo} alt="Logo" style={styles.logo} />
  <View style={{ flexDirection: 'column', alignItems: 'center' }}>
    <Text style={{ fontWeight: "Bold", fontSize: 14 }}> Your School Name here </Text>
    <Text style={{ fontWeight: "Bold", fontSize: 14 }}> address goes here </Text>
  </View>
  <View style={{ width: 10 }} /> 
</View>
</TableRow>
<Text style={[styles.text, { textAlign: "center" }]}>Contact: your no</Text>

          <Text style={styles.header}>Student's Copy</Text>
          <br />
          <Table>
            <TableRow>
              <TableCell>Invoice No</TableCell>
              <TableCell>:  {data.idf}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Admission No</TableCell>
              <TableCell>:  {data.fee_adm_no}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Student Name:</TableCell>
              <TableCell>:  {data.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Father Name:</TableCell>
              <TableCell>:  {data.father}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Standard:</TableCell>
              <TableCell>:  {data.FeeStandard}</TableCell>
            </TableRow>
            <TableRow>
              
              <TableCell>Due Date:</TableCell>
                         <TableCell>:  10-{getMonthName(data.fmonth)}-{data.fyear}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Fee Month:</TableCell>
                         <TableCell>:  {getMonthName(data.fmonth)} - {data.fyear}</TableCell>
            </TableRow>
          </Table>
          <hr />
          <LineBreak />
          <HorizontalLine />
    
          <Text style={styles.header}>Fee Detail</Text>
          <Table>
            <TableRow>
              <TableCellColor>Description</TableCellColor>
              <TableCellColor>Amount</TableCellColor>
            </TableRow>
            <TableRow>
              <TableCell>Monthly Fee:</TableCell>
              <TableCell>Rs. {data.monthly_fee_feetbl}/-</TableCell>
            </TableRow>

            <TableRow>
              {data.misc_fee > 0 ? (
                <>
                  <TableCell>{data.remarks}:</TableCell>
                  <TableCell>Rs. {data.misc_fee}/-</TableCell>
                </>
              ) : null}
            </TableRow>

            <TableRow>
              {data.exam_fee > 0 ? (
                <>
                  <TableCell>Adm Fee:</TableCell>
                  <TableCell>Rs. {data.adm_fee}/-</TableCell>
                </>
              ) : null}
            </TableRow>
            <TableRow>
              {data.exam_fee > 0 ? (
                <>
                  <TableCell>Exam Fee:</TableCell>
                  <TableCell>Rs. {data.exam_fee}/-</TableCell>
                </>
              ) : null}
            </TableRow>
            <TableRow>
              {data.fine_fee > 0 ? (
                <>
                  <TableCell>Fine Fee:</TableCell>
                  <TableCell>Rs. {data.fine_fee}/-</TableCell>
                </>
              ) : null}
            </TableRow>
            <TableRow>
              {data.arrears > 0 ? (
                <>
                  <TableCell>Arrears:</TableCell>
                  <TableCell>Rs. {data.arrears}/-</TableCell>
                </>
              ) : null}
            </TableRow>
            <TableRow>
  {data.total_fee > 0 ? (
    <>
      <TableCellColor>Payable before due date</TableCellColor>
      <TableCellColor>Rs. {data.total_fee}/-</TableCellColor>
    </>
  ) : null}
</TableRow>
<TableRow>
 
    <>
      <TableCell>Late Fee Fine</TableCell>
      <TableCell>Rs. 100/-</TableCell>
    </>

</TableRow>
<TableRow>
<>
      <TableCellColor>Payable after due date</TableCellColor>
      <TableCellColor>Rs. {parseInt(data.total_fee) + 100}/-</TableCellColor>
    </>
 
</TableRow>
          </Table>
       
          <LineBreak />
          <LineBreak />
          <LineBreak />
          <LineBreak />
          <LineBreak />
         
    {/* Footer */}
    <View style={styles.footer}>
       
            <Text style={{ fontSize: 10 }}>
              1. Fee Dues must be paid before 10th of every month.
            </Text>
            <Text style={{ fontSize: 10 }}>
              2. Late fee fine (100) will be charged after due date.
            </Text>
            <Text style={{ fontSize: 10 }}>
              3. Defaulters of more than two months will be strucked off from the school.
            </Text>
           
        <HorizontalLine/>
          <Text style={{ fontSize: 10, textAlign: "center" }}>
            your bank detail
          </Text>
          <Text style={{ fontSize: 10, textAlign: "center" }}>
            A/C No: account no???
          </Text>
        </View>
        </View>
          </Page>
        ))}
      </Document>
    );
  };


export default BulkPdf;
