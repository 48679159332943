import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import userContext from "../context/UserContext";

const Report = () => {
  const { token } = useContext(userContext);
  const [reportData, setReportData] = useState([]);
 
  useEffect(() => {
    const fetchReportData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/feestats/reporting`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        console.log("Fetched report data:", response.data);
        setReportData(response.data.data);
      } catch (error) {
        console.error("Error fetching report data:", error);
      }
    };

    fetchReportData();
  }, [token]);

  return (
    <>
      <h2 className="text-center">COLLECTION REPORT IN VARIOUS HEADS</h2>
      <table className="table table-striped">
        <thead className="thead-dark">
          <tr>
            <th>Ser</th>
            <th>M / Y</th>
            <th>Fee / Get </th>
            <th>Fine / Get</th>
            <th>Adm Fee / Get</th>
            <th>Misc Fee / Get</th>
            <th>Exam Fee / Get</th>
            <th>Total Fee / Get</th>
          </tr>
        </thead>
        <tbody>
          {reportData.length > 0 ? (
            reportData.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>
                  {item.fmonth} / {item.fyear}
                </td>
                <td>
                  {item.monthly_fee_feetbl} / {item.collection}
                </td>
                <td>
                  {item.fine_fee} / {item.fine_collection}
                </td>
                <td>
                  {item.adm_fee} / {item.adm_collection}
                </td>
                <td>
                  {item.misc_fee} / {item.misc_collection}
                </td>
                <td>
                  {item.exam_fee} / {item.exam_collection}
                </td>
                <td>
                  {item.total_fee} / {item.total_collection}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8" className="text-center">
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default Report;
