import React from 'react'


export default function About() {
  return (
    <>
    <div className='mt-2'>

  
    <p>This all about About page..</p>
    </div>
      
    </>
  )
}
