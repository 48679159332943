import React, { useEffect, useState, useContext } from "react"; 
import axios from "axios"; 
import { useNavigate } from "react-router-dom"; 
import userContext from "../context/UserContext"; 
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa"; 

export default function StudentList() {
  const navigate = useNavigate(); 
  axios.defaults.withCredentials = true; 
  const [students, setStudents] = useState([]); 
  const [search, setSearch] = useState(""); 
  const { token } = useContext(userContext); 

  // Pagination state 
  const [currentPage, setCurrentPage] = useState(1); 
  const recordsPerPage = 50; // items to display per page 

  // State for inserting fee 
  const [selectedDate, setSelectedDate] = useState(""); 

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/students`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setStudents(response.data);
      } catch (error) {
        console.error("Error fetching students:", error);
      }
    };
    fetchStudents();
  }, [token]);

  const handleClick = () => {
    navigate("/dashboard/studentcreate");
  };

  const handleDelete = async (admNo) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this record?");
    if (confirmDelete) {
      const originalStudents = [...students]; // Save original state
      setStudents(students.filter((student) => student.adm_no !== admNo)); // Optimistically update
      try {
        await axios.delete(`${process.env.REACT_APP_API_URL}/students/del/${admNo}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } catch (error) {
        console.error("Error deleting student:", error);
        setStudents(originalStudents); // Revert back to original state
        alert("Failed to delete the student. Please try again."); // Notify user
      }
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  const handleEdit = (id) => {
    navigate(`/dashboard/studentedit/${id}`);
  };


  // Filtering students 
  const filteredStudents = students.filter((student) => {
    return (
      student.adm_no.toString().includes(search) ||
      student.name.toLowerCase().includes(search.toLowerCase()) ||
      student.standard.toLowerCase().includes(search.toLowerCase())
    );
  });

  // Count of active students
  const activeStudentsCount = students.length; // Adjust the logic if you have a specific criteria

  // Pagination logic
  const totalPages = Math.ceil(filteredStudents.length / recordsPerPage); 
  const startIndex = (currentPage - 1) * recordsPerPage; 
  const currentStudents = filteredStudents.slice(startIndex, startIndex + recordsPerPage); 

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle fee insertion
  const handleInsertFee = async (admNo, standard, section, monthlyFee) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/fee/insert-single`, 
            { 
                adm_no: admNo, 
                selected_date: selectedDate, 
                standard,
                section,
                monthly_fee: monthlyFee
            }, 
            { headers: {
                Authorization: `Bearer ${token}`,
            }});
        
        // Handle success response here
        if (response.data.success) {
            alert("Fee record inserted successfully!");
        } else {
            alert(response.data.message || "An error occurred.");
        }
    } catch (error) {
        console.error("Failed :", error.response ? error.response.data : error.message);
        alert("Failed : " + (error.response ? error.response.data.message : error.message));
    }
  };
  


  return (
    <div className="card">
      <div className="card-header">
        <h1 className="text-center"><b>STUDENTS</b></h1>
        <div className="row align-items-center">
          <div className="col-md-4 text-center">
             </div>
          <div className="row align-items-center">
            <div className="col-md-3">
              <button className="btn btn-primary" onClick={handleClick}>
                Add New
              </button>
              <button className="btn btn-info ml-1"><b>
              {activeStudentsCount}</b>
              </button>  
             
             
              
         
            </div>
            <div className="col-md-9">
              <label htmlFor="searchInput" className="form-label d-none">Search here...</label>
              <input
                type="text"
                className="form-control border-pill border-3 border-info"
                id="searchInput"
                value={search}
                onChange={handleSearch}
                placeholder="Search by Adm No, Name, or Standard"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="card-body">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Adm No</th>
              <th>Name</th>
              <th>Father</th>
              <th>Class</th>
              <th>Sec</th>
              <th>Image</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentStudents.map((student) => (
              <tr key={student.id}>
                <td>{student.adm_no}</td>
                <td>{student.name}</td>
                <td>{student.father}</td>
                <td>{student.standard}</td>
                <td>{student.section}</td>
                <td className="d-flex justify-content-center align-items-center" style={{ height: "70px" }}>
                  {student.image && (
                    <img
                      src={`${process.env.REACT_APP_API_URL}/${student.image}`}
                      alt={student.name}
                      style={{ width: "70px", height: "70px" }}
                    />
                  )}
                </td>
                <td>
                  <input
                    type="date"
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e.target.value)}
                    required
                  />
                  <button 
                    className="btn btn-success btn-sm" 
                    onClick={() => { 
                      handleInsertFee(student.adm_no, student.standard, student.section, student.monthly_fee); 
                    }}
                  >
                    <FaPlus/>
                  </button>
                  <button
                    className="btn btn-danger btn-sm ml-1"
                    onClick={() => handleEdit(student.id)}
                  >
                    <FaEdit />
                  </button>
                  <button
                    className="btn btn-danger btn-sm ml-1"
                    onClick={() => handleDelete(student.adm_no)}
                  >
                    <FaTrash />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Pagination Controls */}
        <div className="pagination">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              className={`btn ${currentPage === index + 1 ? "btn-success" : "btn-primary"}`}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
