import React, { useState, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import userContext from "./context/UserContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "./CSS/Login.css";

const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const { setUser, setToken, setUserType } = useContext(userContext); // add setUserType here

    const handleLogin = async (e) => {
        e.preventDefault();
        const credentials = { username, password };
    
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/user/login`,
                credentials,
                {
                    headers: { "Content-Type": "application/json" },
                }
            );
    
            //console.log("API Response: ", response.data); // Log the API response
            setUser({ username });
            
            // Fix: Access usertype from the correct location in the response
            if(response.data.user && response.data.user.usertype) {
                setUserType({ usertype: response.data.user.usertype });
            } else {
                console.error("Usertype is not present in the response");
            }
    
            setToken(response.data.token);
            navigate("/dashboard/main");
        } catch (err) {
            setError(
                err.response
                    ? err.response.data.message
                    : "Login failed. Please try again."
            );
            console.error("Login error: ", err); // Log any errors received from the axios call
        }
    };
    
    const handleBack = () => {
        navigate('/'); // Navigate back to the home page
    };

    return (
        <div className="login-background">
            <div className="container col-md-4 login-box">
                <h1 className="my-4 text-center">Welcome!</h1>
                <h3 className="my-4 text-center">Please Login</h3>
                <form onSubmit={handleLogin}>
                    <div className="form-group">
                        <label htmlFor="usernameInput">Username</label>
                        <input
                            type="text"
                            className="form-control"
                            id="usernameInput"
                            placeholder="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                            autoComplete="new-password" 
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="passwordInput">Password</label>
                        <input
                            type="password"
                            className="form-control"
                            id="passwordInput"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            autoComplete="new-password" 
                        />
                    </div>
                    <br />
                    {error && <p className="text-danger">{error}</p>}
                    <button type="submit" className="btn btn-primary">
                        Login
                    </button>
                    <button type="button" className="btn btn-secondary ml-2" onClick={handleBack}>
                        Back
                    </button>
                </form>
                <div className="contact-info">
                    <p>Contact us: <a href="mailto:techinfolab360@gmail.com" style={{ color: 'lightblue' }}>techinfolab360@gmail.com</a></p>
                    <p>Website: <a href="https://techinfolab360.xyz" style={{ color: 'lightblue' }}>https://techinfolab360.xyz</a></p>
                </div>
            </div>
        </div>
    );
};

export default Login;
