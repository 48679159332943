import React, { useState, useContext } from 'react';
import axios from 'axios';
import userContext from '../context/UserContext';
import { useNavigate } from "react-router-dom";
import FeePdf from './Feepdf';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { FaEdit, FaTrash, FaFilePdf, FaGoogleWallet, FaComments } from "react-icons/fa"; 

const FeeSearchCollect = () => {
  const { token } = useContext(userContext);
  const [error, setError] = useState(null);
  const [searchParams, setSearchParams] = useState({ searchValue: "" });
  const [fetchedInvoices, setFetchedInvoices] = useState([]);
  const [miscFee, setMiscFee] = useState("");
  const [remarks, setRemarks] = useState("");
  const [updatingId, setUpdatingId] = useState(null);
  const [searchPerformed, setSearchPerformed] = useState(false);
  
  const navigate = useNavigate();
  const currentMonth = new Date().getMonth() + 1;

  const handleSendSms = async (inv) => {
    const message = `Respected Parents! Fee of ${inv.name} for the month of ${inv.fmonth}/${inv.fyear} is Rs.${inv.total_fee}/-. You are requested to submit fee before 10-${inv.fmonth}-${inv.fyear}`;
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/sms/send-sms`, {
        number: '0' + inv.mobile,
        msg: message
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      alert(response.data);
    } catch (error) {
      console.error("Error sending SMS:", error);
      alert("Failed to send SMS.");
    }
  };

  const handleSearch = async () => {
    const { searchValue } = searchParams;

    if (!searchValue || !searchValue.trim()) {
      alert("Please enter a search term.");
      return;
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/unpaid/search`, {
        params: { query: searchValue },
        headers: { Authorization: `Bearer ${token}` },
      });
      setFetchedInvoices(response.data.data || []);
      setSearchPerformed(true);
    } catch (error) {
      setError("Error searching invoices: " + error.message);
      console.error("Error searching invoices:", error);
    }
  };

  const handleDelete = async (idf) => {
    if (window.confirm("Are you sure you want to delete this record?")) {
      try {
        await axios.delete(`${process.env.REACT_APP_API_URL}/fee/${idf}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        alert("Record deleted successfully.");
        await handleSearch();
      } catch (error) {
        console.error("Error deleting record:", error);
        alert("Failed to delete record.");
      }
    }
  };

  const handleCollect = (idf) => {
    navigate(`/dashboard/feecollection/${idf}`);
  };

  const handleEdit = (idf) => {
    navigate(`/dashboard/feeedit/${idf}`);
  };

  const handleUpdateMiscFee = async (idf) => {
    if (!miscFee || !remarks) {
      alert("Misc fee and remarks are required.");
      return;
    }

    const invoiceToUpdate = fetchedInvoices.find(inv => inv.idf === idf);
    if (invoiceToUpdate.collection > 0) {
      alert("Fee already collected for this invoice.");
      return;
    }

    const newTotalFee = parseFloat(invoiceToUpdate.total_fee) + parseFloat(miscFee);

    try {
      await axios.patch(`${process.env.REACT_APP_API_URL}/unpaid/misc/${idf}`, {
        idf,
        miscfee: miscFee,
        remarks,
        total_fee: newTotalFee
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });

      alert("Misc fee and remarks updated successfully.");
      setFetchedInvoices(prev =>
        prev.map(inv =>
          inv.idf === idf ? { ...inv, misc_fee: miscFee, remarks, total_fee: newTotalFee } : inv
        )
      );
      setMiscFee("");
      setRemarks("");
      setUpdatingId(null);
    } catch (error) {
      console.error("Error updating misc fee:", error);
      alert("Failed to update misc fee.");
    }
  };

  return (
    <div className="container">
      {error && <div className="alert alert-danger">{error}</div>}
      <div className='row mb-3 align-items-center'>
        <div className='col-md-2'>
          <label><strong>Search by Adm No:</strong></label>
        </div>
        <div className="col-md-4">
          <input
            type="text"
            value={searchParams.searchValue}
            onChange={(e) => setSearchParams({ searchValue: e.target.value })} 
            placeholder="Adm No here..."
            className="form-control mx-2 shadow p-2 rounded"
          />
        </div>

        <div className="col-md-2 text-center">
          <button
            onClick={handleSearch}
            className="btn btn-sm shadow p-2 rounded"
            style={{ backgroundColor: "#61197d", color: "white" }}
          >
            <b>Search</b>
          </button>
        </div>
      </div>

      {searchPerformed && (
        <>
          <hr />
          <div className="row">
            {fetchedInvoices.length === 0 ? (
              <div className="col-12 text-center">No records found.</div>
            ) : (
              fetchedInvoices.map((inv) => (
                <div key={inv.idf} className="col-12 mb-3 border p-3 rounded">
                  <div className="row">
                    <div className="col-md-2"><strong>No:</strong> {inv.idf}-{inv.fee_adm_no}</div>
                    <div className="col-md-4"><strong>Name:</strong> <strong style={{ color: 'blue' }}>{inv.name}</strong></div>
                    <div className="col-md-2"><strong>Standard:</strong> {inv.FeeStandard}</div>
                    <div className="col-md-2">
                      <span className={`badge ${Number(inv.fmonth) === currentMonth ? "bg-success" : "bg-primary"} text-white`}>
                        <strong>M / Y:</strong> {inv.fmonth} / {inv.fyear}
                      </span>
                    </div>
                    <div className="col-md-2"><strong>Monthly Fee:</strong> {inv.monthly_fee_feetbl}</div>
                  </div>

                  <hr />

                  <div className="row">
                    <div className="col-md-2"><strong>Exam Fee:</strong> {inv.exam_fee}</div>
                    <div className="col-md-2"><strong>Adm Fee:</strong> {inv.adm_fee}</div>
                    <div className="col-md-2"><strong>Fine:</strong> {inv.fine_fee}</div>
                    <div className="col-md-2"><strong>Misc Fee:</strong> {inv.misc_fee} ({inv.remarks})</div>
                    <div className="col-md-2"><strong>Arrears:</strong> {inv.total_arrears}</div>
                    <div className="col-md-2 text-center">
                      <button className={inv.collection > 0 ? 'btn btn-success' : 'btn btn-danger'}>
                        {inv.collection > 0 ? 'Paid' : 'Unpaid'}
                      </button>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-2"><strong>Balance:</strong> {inv.total_balance}</div>
                    <div className="col-md-2"><strong>Collection:</strong> {inv.total_collection}</div>
                    <div className="col-md-2"><strong>Paid On:</strong> {inv.payment_at}</div>
                    <div className="col-md-2"><strong>Collection by:</strong> {inv.collection_by}</div>
                  </div>

                  <hr/>

                  <form onSubmit={(e) => {
                    e.preventDefault();
                    handleUpdateMiscFee(inv.idf);
                  }}>
                    <div className="row">
                      <div className="col-md-3">
                        <label><strong>Extra Fee:</strong></label>
                        <input
                          type="number"
                          value={updatingId === inv.idf ? miscFee : ''}
                          onChange={(e) => {
                            setMiscFee(e.target.value);
                            setUpdatingId(inv.idf);
                          }}
                          placeholder="Misc Fee Amount"
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-3">
                        <label><strong>Extra Fee Remarks:</strong></label>
                        <input
                          type="text"
                          value={updatingId === inv.idf ? remarks : ''}
                          onChange={(e) => {
                            setRemarks(e.target.value);
                            setUpdatingId(inv.idf);
                          }}
                          placeholder="Misc Fee Remarks"
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-3">
                        <label><strong>Total Fee:</strong></label>
                        <input
                          type="text"
                          value={`Rs ${updatingId === inv.idf ? (parseFloat(inv.total_fee) + parseFloat(miscFee || 0)).toFixed(2) : inv.total_fee}/-`}
                          readOnly
                          className="form-control"
                          style={{ backgroundColor: 'white', color: 'black', fontWeight: 'bold' }}
                        />
                      </div>
                      <div className='col-md-2'>
                        <br/>
                        <button type="submit" className="btn btn-sm" style={{ backgroundColor: "blue", color: "white" }}>Update</button>
                      </div>
                    </div>
                  </form>

                  <div className="mt-2">
                    <button className="btn btn-success" onClick={() => handleCollect(inv.idf)}><FaGoogleWallet /></button>
                    <button className="btn btn-success ml-1" onClick={() => handleEdit(inv.idf)}><FaEdit /></button>
                    <button className="btn btn-danger ml-1" onClick={() => handleDelete(inv.idf)}><FaTrash /></button>
                    <PDFDownloadLink 
                      fileName="Invoice.pdf" 
                      document={<FeePdf data={inv} />} 
                      style={{ textDecoration: 'none', padding: '10px' }}
                    >
                      <button className="btn btn-info ml-1">
                        <FaFilePdf />
                      </button>
                    </PDFDownloadLink>
                    <button className="btn btn-warning ml-1" onClick={() => handleSendSms(inv)}>
                      <FaComments />
                    </button>
                  </div>
                </div>
              ))
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default FeeSearchCollect;
