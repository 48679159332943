import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';

import userContext from '../context/UserContext';

const AnnouncementPublished = () => {
  const [announcements, setAnnouncements] = useState([]);
  const { token } = useContext(userContext);


  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/info`);
        setAnnouncements(response.data);
      } catch (err) {
        console.error("Error fetching announcements:", err);
      }
    };
    fetchAnnouncements();
  }, [token]);
  // Function to format the date
  const formatDate = (dateString) => {
    return dateString.split('T')[0]; // This splits the string at 'T' and takes the first part (the date).
  }
 
  return (
    <div className="card col-md-12 mx-auto">
      <div className="card-header">
        <h2 className="text-center">Important Announcements</h2>
       
      </div>
      <div className="card-body">
        <table className="table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Title</th>
              <th>Announcement</th>
           
           
            </tr>
          </thead>
          <tbody>
            {announcements.map(announcement => (
              <tr key={announcement.id}>
                 <td>{formatDate(announcement.created_at)}</td>
                <td>{announcement.title}</td>
                <td>{announcement.description}</td>
                <td>
                  {announcement.image && (
                    <img src={
                      `${process.env.REACT_APP_API_URL}/${announcement.image}`} alt={announcement.title} style={{ width: '100px', height: '100px' }} />
                  )}
                </td>
             
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AnnouncementPublished;
