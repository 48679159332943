import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import userContext from "../context/UserContext";
import { useNavigate } from "react-router-dom";

export default function FeeCollection() {
  const { user } = useContext(userContext);
  const { idf } = useParams();
  const { token } = useContext(userContext);
  const navigate = useNavigate();

  const [feedetail, setFeeDetail] = useState({
    fee_adm_no: "",
    idf: "",
    feestandard: "",
    monthly_fee: "",
    collection: "",
    fine_collection: "",
    adm_collection: "",
    misc_collection: "",
    exam_collection: "",
    total_fee: "",
    fine_fee: "",
    exam_fee: "",
    misc_fee: "",
    adm_fee: "",
    arrears: "",
    fine_arrears: "",
    adm_arrears: "",
    exam_arrears: "",
    misc_arrears: "",
    total_arrears: "",
    total_balance: "",
  });

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1; // getMonth() returns 0-based index
  
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/fee/${idf}?fyear=${currentYear}&fmonth=${currentMonth}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
  
        if (response.data.success && response.data.data.length > 0) {
          setFeeDetail(response.data.data[0]);
        }
      } catch (error) {
        console.error("Error fetching student:", error);
      }
    };
    fetchInvoices();
  }, [idf, token]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFeeDetail((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUpdate = async () => {
    const collectionAmount = parseFloat(feedetail.collection);
    if (isNaN(collectionAmount) || collectionAmount <= 0) {
        alert("Fee Collection Amount should be greater than 0.");
        return;
    }

    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, "0")}-${String(currentDate.getDate()).padStart(2, "0")}`;

    const dataToUpdate = {
        collection: feedetail.collection,
        fine_collection: feedetail.fine_collection,
        adm_collection: feedetail.adm_collection,
        misc_collection: feedetail.misc_collection,
        exam_collection: feedetail.exam_collection,
        fine_arrears: feedetail.fine_arrears,
        collection_by: user.username,
        payment_at: formattedDate,
    };

    console.log("Data to update: ", dataToUpdate);

    try {
        const response = await axios.patch(
            `${process.env.REACT_APP_API_URL}/fee/${idf}`,
            dataToUpdate,
            { headers: { Authorization: `Bearer ${token}` } }
        );

        if (response.data.success) {
            alert("Update successful!");
            navigate("/dashboard/feesearchcollect");
        } else {
            alert("Update failed: " + response.data.message); // This now handles year/month mismatch errors appropriately
        }
    } catch (error) {
        console.error("Error updating fee detail:", error);
        alert("Error updating the fee detail.");
    }
};


  

  const handleback = (e) => {
    navigate("/dashboard/feesearchcollect");
  };

  return (
    <div className="container">
      <div className="row">
        <h2 className="text-center" style={{ color: "#0b4f6c" }}>
          Fee Collection
        </h2>
      </div>
      <div className="row">
        <table>
          <tbody>
            <tr>
              <td>
                <label className="form-control">Invoice</label>
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  name="idf"
                  value={feedetail?.idf || ""}
                  disabled
                /> 
              </td>
             
              <td>
                <label className="form-control">Name</label>
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={feedetail?.name || ""}
                  disabled
                />
              </td>
              <td>
                <label className="form-control">Total Fee</label>
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  name="total_fee"
                  value={feedetail?.total_fee || ""}
                  disabled
                />
              </td>
            </tr>

            <tr>

              <td>
                <label className="form-control">Monthly Fee</label>
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  name="monthly_fee"
                  value={feedetail?.monthly_fee_feetbl || ""}
                  disabled
                />
              </td>
              <td>
              {/* Monthly Fee Arrears */}
                <label className="form-control">Arrears</label>
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  name="arrears"
                  value={feedetail?.arrears || ""}
                  disabled
                />
              </td>
             
              <td>
                <label className="form-control">Receive</label>
              </td>
              <td>
                <input
                  type="text"
                  className="form-control bg-success text-white"
                  name="collection"
                  value={feedetail?.collection || ""}
                  onChange={handleChange}
                />
              </td>
             
            </tr>
            <tr>
              <td>
                <label className="form-control">Misc Fee</label>
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  name="misc_fee"
                  value={feedetail?.misc_fee || ""}
                  disabled
                />
              </td>
              <td>
                <label className="form-control">Arrears</label>
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  name="misc_arrears"
                  value={feedetail?.misc_arrears || ""}
                  onChange={handleChange}
                  disabled
                />
              </td>
              <td>
                <label className="form-control">Receive</label>
              </td>
              <td>
                <input
                  type="text"
                  className="form-control bg-success text-white"
                  name="misc_collection"
                  value={feedetail?.misc_collection || ""}
                  onChange={handleChange}
                />
              </td>
             
              </tr>
              <tr>
              <td>
                <label className="form-control">Adm Fee</label>
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  name="adm_fee"
                  value={feedetail?.adm_fee || ""}
                  onChange={handleChange}
                  disabled
                />
              </td> 
              <td>
                <label className="form-control">Arrears</label>
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  name="adm_arrears"
                  value={feedetail?.adm_arrears || ""}
                  onChange={handleChange}
                  disabled
                />
              </td>
              <td>
                <label className="form-control">Receive</label>
              </td>
              <td>
                <input
                  type="text"
                  className="form-control bg-success text-white"
                  name="adm_collection"
                  value={feedetail?.adm_collection || ""}
                  onChange={handleChange}
                />
              </td>
              
              
            </tr>
            
            <tr>
            <td>
                <label className="form-control">Fine</label>
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  name="fine_fee"
                  value={feedetail?.fine_fee || ""}
                  onChange={handleChange}
                  disabled
                />
              </td>
            <td>
                <label className="form-control">Arrears</label>
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  name="fine_arrears"
                  value={feedetail?.fine_arrears || ""}
                  onChange={handleChange}
                  disabled
                />
              </td>
              <td>
                <label className="form-control">Receive</label>
              </td>
              <td>
                <input
                  type="text"
                  className="form-control bg-success text-white"
                  name="fine_collection"
                  value={feedetail?.fine_collection || ""}
                  onChange={handleChange}
                />
              </td>

            </tr>
            <tr>
              
            
              <td>
                <label className="form-control">Exam Fee</label>
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  name="exam_fee"
                  value={feedetail?.exam_fee || ""}
                  disabled
                />
              </td>
              
              <td>
                <label className="form-control">Arrears</label>
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  name="exam_arrears"
                  value={feedetail?.exam_arrears || ""}
                  onChange={handleChange}
                  disabled
                />
              </td>
              <td>
                <label className="form-control">Receive</label>
              </td>
              <td>
                <input
                  type="text"
                  
                  className="form-control bg-success text-white"
                  name="exam_collection"
                  value={feedetail?.exam_collection || ""}
                  onChange={handleChange}
                />
              </td>
              
            </tr>
           
            <tr>
              <td>
                <button className="btn btn-success text-center" onClick={handleUpdate}>
                  Update
                </button>
                </td><td>
                <button className="btn btn-secondary ml-2 text-center" onClick={handleback}>
                  Back
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
