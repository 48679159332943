import React, { useState } from 'react';
import axios from 'axios';

const TasksView = () => {
    const [tasks, setTasks] = useState([]);
    const [admno, setAdmno] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        if (!admno) {
            setError('Please enter an admission number.');
            setLoading(false);
            return;
        }

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/tasks/view`, {
                params: { admno },
            });
            console.log("Fetched tasks:", response.data);
            setTasks(response.data.data || []); // Assuming a fallback if data is undefined
        } catch (error) {
            console.error("Error fetching assignments:", error.response ? error.response.data : error.message);
            setError('Error fetching assignments');
        } finally {
            setLoading(false);
        }
    };

    // Check if there's any task data and get the first task to display its details
    const firstTask = tasks.length ? tasks[0] : null;

    return (
        <div className="container">
          
                <h1 style={{ color: "#0b4f6c", textAlign: 'center' }}>
                   View Assignments
                </h1>
           
            {error && <div className="alert alert-danger">{error}</div>}

            <form onSubmit={handleSubmit} className="row mb-3 align-items-center">
                <div className="col-md-8">
                    <input
                        value={admno}
                        onChange={(e) => setAdmno(e.target.value)} 
                        placeholder="Enter Admission Number"
                        className="form-control mx-2 shadow p-2 rounded-pill border-3 border-info"
                    />
                </div>

                <div className="col-md-4 text-center">
                    <button
                        type="submit"
                        className="btn btn-primary shadow p-2 rounded"
                        style={{ backgroundColor: "#0b4f6c", color: "white" }}
                    >
                        <b>Search</b>
                    </button>
                </div>
            </form>
            <hr/>
            {firstTask && (
                <h1 style={{ color: "#0b4f6c", textAlign: 'center' }}>
                    <b>
                         {firstTask.adm_no} {firstTask.name}, 
                        Class: {firstTask.task_standard}
  
                    </b>
                </h1>
            )}
            <hr/>

            {loading ? (
                <div>Loading...</div>
            ) : (
                tasks.length > 0 ? (
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th style={{ width: '10%' }}>Date</th>
                                <th style={{ width: '10%' }}>Subject</th>
                                <th style={{ width: '10%' }}>Teacher</th>
                                <th style={{ width: '60%' }}>Task</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tasks.map((task) => (
                                <tr key={task.syllabus_id}> 
                                    <td>
                                    {new Date(firstTask.created_task_at).toLocaleDateString()}

                                    </td>
                                    <td>{task.user}</td>
                                    
                                    <td>{task.subject}</td>
                                    <td>{task.task}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <div>No tasks found</div>
                )
            )}
        </div>
    );
};

export default TasksView;
